<template>
    <h1>Organization Management</h1>

    <div class="row">
        <div class="col-4">
            <label>Organization Type</label>
            <select class="form-select" v-model="selectedOrganizationType" :id="selectedOrganizationType"
                    @change="changeOrganizationType" style="height: 41px">
                <option :value="-1">Select an Organization Type</option>
                <option :value="1">Nebraska Educational Service Unit (ESU)</option>
                <option :value="2">Nebraska District</option>
                <option :value="3">Nebraska School</option>
                <option :value="4">Outstate Organization</option>
            </select>
        </div>
        <div class="col-sm-3 offset-5" style="margin-bottom: 1%">
            <label>Filter By:</label>
            <input class="form-control" type="search" v-model="searchText"
                   :placeholder="searchHintText" aria-label="Search Query" style="height: 41px;" :disabled="!isLoaded"/>
        </div>
    </div>
    <div v-if="isLoaded">

        <MDBDatatable :dataset="organizationData" :search="searchText" striped :entries="25" :max-height="635"
                      fixed-header/>
    </div>
</template>

<script>
import {onBeforeMount, ref} from "vue";
import axios from "axios";
import {MDBDatatable} from "mdb-vue-ui-kit";
import {API_URL} from "../../../../Constants";

export default {
	name: "OrganizationTableV2",
	// eslint-disable-next-line vue/no-unused-components
	components: {MDBDatatable},
	setup() {
		const selectedOrganizationType = ref(-1);
		const searchText = ref("");
		const filterParam = ref("");
		const searchHintText = ref("");
		const searchColumns = ref([]);
		const isLoaded = ref(false);
		const organizationData = ref({
			columns: [],
			rows: []
		});

		onBeforeMount(() => {
			if (localStorage.getItem("storedOrganizationType")) {
				selectedOrganizationType.value = parseInt(localStorage.getItem("storedOrganizationType"));
				changeOrganizationType();
			}
		})

		function changeOrganizationType() {
			isLoaded.value = false;
			localStorage.setItem("storedOrganizationType", selectedOrganizationType.value);
			searchText.value = "";
			filterParam.value = "";
			switch (parseInt(selectedOrganizationType.value)) {
				case 1:
					getEsus();
					setEsuHeaders();
					searchHintText.value = "ESU Name, Address, City";
					break;
				case 2:
					getDistricts();
					setDistrictHeaders();
					searchHintText.value = "District Name, ESU, Address, City";
					break;
				case 3:
					setSchoolHeaders();
					getSchools();
					searchHintText.value = "School Name, District, ESU, Address, City";
					break;
				case 4:
					setOutstateOrganizationHeaders();
					getOutstateOrganizations();
					searchHintText.value = "Name, Address, City, State, Country";
					break;
				default:
					clearOldOrganizations();
					clearOrgHeaders();
					break;
			}

		}

		function setSchoolHeaders() {
			organizationData.value.columns = [
				{field: "name", label: "School Name"},
				{field: "cdn", label: "State ID"},
				{field: "district", label: "District"},
				{field: "esu", label: "ESU"},
				{field: "address", label: "School Address"},
				{field: "phoneNumber", label: "Phone Number"},
				{field: "city", label: "City"},
				{field: "zipCode", label: "ZIP"},
			]
		}

		function setOutstateOrganizationHeaders() {
			organizationData.value.columns = [
				{field: "name", label: "Institution Name"},
				{field: "contactName", label: "Contact Name"},
				{field: "contactEmail", label: "Contact Email"},
				{field: "phoneNumber", label: "Phone Number"},
				{field: "address", label: "Institution Address"},
				{field: "city", label: "City"},
				{field: "state", label: "State"},
				{field: "country", label: "Country"},
				{field: "postalCode", label: "Postal Code (ZIP CODE)"}
			]
		}

		function setDistrictHeaders() {
			organizationData.value.columns = [
				{field: "name", label: "District Name"},
				{field: "cdn", label: "CDN"},
				{field: "esu", label: "ESU"},
				{field: "address", label: "Primary Address"},
				{field: "phoneNumber", label: "Primary Phone Number"},
				{field: "city", label: "City"},
				{field: "zipCode", label: "Zip"}
			]
		}

		function setEsuHeaders() {
			organizationData.value.columns = [
				{field: "name", label: "ESU Name"},
				{field: "cdn", label: "CDN"},
				{field: "address", label: "Primary Address"},
				{field: "phoneNumber", label: "Primary Phone Number"},
				{field: "city", label: "City"},
			]
		}

		function clearOldOrganizations() {
			while (organizationData.value.rows.length > 0) {
				organizationData.value.rows.pop();
			}
		}

		function clearOrgHeaders() {
			while (organizationData.value.columns.length > 0) {
				organizationData.value.columns.pop();
			}
		}

		async function getEsus() {
			let get_uri = API_URL + "/esu/displayAll?_isDisabled=" + btoa('false');
			await axios.get(get_uri)
				.then((result) => {
					result.data.sort((a, b) => a.esuName > b.esuName ? 1 : -1);
					let institution_temp = [];
					for (let i = 0; i < result.data.length; i++) {
						let institution = {
							name: `<span style="display: none">${result.data[i]['esuName']}</span><a href="/viewEsu/${result.data[i]['idEsu']}">${result.data[i]['esuName']}</a>`,
							address: result.data[i]['primaryEsuAddress'],
							phoneNumber: result.data[i]['esuPhoneNumber'],
							city: result.data[i]['esuCity'],
							cdn: result.data[i]['esuCdn'] !== null ? result.data[i]['esuCdn'] : "",
							idOrganization: result.data[i]['idEsu']
						}
						institution_temp.push(institution);
					}
					organizationData.value.rows = institution_temp;
				}).finally(() => {
					isLoaded.value = true;
				})
		}

		async function getDistricts() {
			let get_uri = API_URL + "/districts/allForDisplay";
			await axios.get(get_uri)
				.then((result) => {
					result.data.sort((a, b) => a.districtName > b.districtName ? 1 : -1);
					let institution_temp = [];
					for (let i = 0; i < result.data.length; i++) {
						let institution = {
							name: `<span style="display: none">${result.data[i]['districtName']}</span><a href="/viewDistrict/${result.data[i]['idDistrict']}">${result.data[i]['districtName']}</a>`,
							address: result.data[i]['primaryDistrictAddress'],
							phoneNumber: result.data[i]['districtPhoneNumber'],
							city: result.data[i]['districtCity'],
							cdn: result.data[i]['districtCdn'] !== null ? result.data[i]['districtCdn'] : "",
							idOrganization: result.data[i]['idDistrict'],
							esu: result.data[i]['districtEsu'],
							zipCode: result.data[i]['districtZipCode']
						}
						institution_temp.push(institution);
					}
					organizationData.value.rows = institution_temp;
				}).finally(() => {
					isLoaded.value = true;
				})
		}

		async function getSchools() {
			setSchoolHeaders();
			let get_uri = API_URL + "/school/allForDisplay";
			await axios.get(get_uri)
				.then((result) => {
					result.data.sort((a, b) => a.schoolName > b.schoolName ? 1 : -1);
					let institution_temp = [];
					for (let i = 0; i < result.data.length; i++) {
						let institution = {
							name: `<span style="display: none;">${result.data[i]['schoolName']}</span><a href="/viewSchool/${result.data[i]['idSchool']}">${result.data[i]['schoolName']}</a>`,
							address: result.data[i]['primarySchoolAddress'],
							phoneNumber: result.data[i]['schoolPhoneNumber'],
							city: result.data[i]['schoolCity'],
							cdn: result.data[i]['schoolCdn'] !== null ? result.data[i]['schoolCdn'] : "",
							idOrganization: result.data[i]['idSchool'],
							esu: result.data[i]['schoolEsu'],
							zipCode: result.data[i]['schoolZipCode'],
							district: result.data[i]['schoolDistrict']
						}
						institution_temp.push(institution);
					}
					organizationData.value.rows = institution_temp;
				}).finally(() => {
					isLoaded.value = true;
				})
		}

		async function getOutstateOrganizations() {
			let get_uri = API_URL + "/outNeOrganization/all";
			await axios.get(get_uri)
				.then((result) => {
					let institution_temp = [];
					for (let i = 0; i < result.data.length; i++) {
						let institution = {
							name: `<span style="display: none">${result.data[i]['organizationName']}</span><a href="/view/organization/${result.data[i]['idOrganization']}">${result.data[i]['organizationName']}</a>`,
							contactName: (result.data[i]['contactName'] !== null && result.data[i]['contactName'] !== undefined) ? result.data[i]['contactName'] : "",
							contactEmail: (result.data[i]['contactEmail'] !== null && result.data[i]['contactEmail'] !== undefined) ? result.data[i]['contactEmail'] : "",
							phoneNumber: (result.data[i]['phoneNumber'] !== null && result.data[i]['phoneNumber'] !== undefined) ? result.data[i]['phoneNumber'] : "",
							address: (result.data[i]['address'] !== null && result.data[i]['address'] !== undefined) ? result.data[i]['address'] : "",
							city: (result.data[i]['city'] !== null && result.data[i]['city'] !== undefined) ? result.data[i]['city'] : "",
							idOrganization: (result.data[i]['idOrganization'] !== null && result.data[i]['idOrganization'] !== undefined) ? result.data[i]['idOrganization'] : "",
							postalCode: (result.data[i]['postalCode'] !== null && result.data[i]['postalCode'] !== undefined) ? result.data[i]['postalCode'] : "",
							country: (result.data[i]['countryName'] !== null && result.data[i]['countryName'] !== undefined) ? result.data[i]['countryName'] : "",
							state: (result.data[i]['stateName'] !== null && result.data[i]['stateName'] !== undefined) ? result.data[i]['stateName'] : ''
						}
						institution_temp.push(institution);
					}
					organizationData.value.rows = institution_temp;
				}).finally(() => {
					isLoaded.value = true;
				})
		}

		return {
			organizationData,
			selectedOrganizationType,
			searchText,
			searchColumns,
			searchHintText,
			filterParam,
			isLoaded,
			changeOrganizationType
		}
	}
}
</script>

<style scoped>

</style>